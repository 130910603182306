<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<pui-field-set :title="$t('mportfacilitiesformsheet.fieldset.generalinformation')">
				<v-row dense>
					<v-col class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
						<pui-select
							id="`mportfacilities-countrycode`"
							attach="mportfacilities-countrycode"
							:label="$t('mportfacilities.countrycode')"
							toplabel
							v-model="model"
							modelName="mports"
							reactive
							disabled
							required
							:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
							:itemsToSelect="itemsToSelectPort"
							:itemValue="['countrycode', 'portcode']"
							:itemText="(item) => `${item.locode} - ${item.portdescription}`"
							:order="{ locode: 'asc' }"
						></pui-select>
					</v-col>

					<v-col class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
						<pui-text-field
							:id="`mportfacilities-portfacilitygisiscode`"
							v-model="model.portfacilitygisiscode"
							required
							:label="$t('mportfacilities.portfacilitygisiscode')"
							toplabel
							:disabled="!isCreatingElement"
							maxlength="4"
						></pui-text-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
						<pui-text-field
							:id="`mportfacilities-portfacilityname`"
							v-model="model.portfacilityname"
							required
							:disabled="!isCreatingElement"
							:label="$t('mportfacilities.portfacilityname')"
							toplabel
							maxlength="256"
						></pui-text-field>
					</v-col>
				</v-row>
			</pui-field-set>
			<pui-field-set :title="$t('mportfacilitiesformsheet.fieldset.operators')">
				<v-row dense>
					<v-col class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
						<pui-select
							:label="$t('mportfacilities.berthoperators')"
							v-model="model.org"
							toplabel
							clearable
							:itemsToSelect="model.org"
							return-object
							:items="consigneeListBerths"
							itemValue="codorg"
							itemText="descripcion"
							multiple
							:order="{ descripcion: 'asc' }"
							:key="consigneeKey"
						></pui-select>
					</v-col>
					<v-col class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-6 col-xl-6">
						<pui-select
							:id="`portdispatcher-${modelName}`"
							:attach="`portdispatcher-${modelName}`"
							:label="$t('mportfacilities.portdispatcher')"
							toplabel
							clearable
							:disabled="formDisabled"
							:order="{ descripcion: 'asc' }"
							reactive
							v-model="model"
							:items="consigneeListDis"
							:itemsToSelect="itemsToSelectPortdispatcher"
							:modelFormMapping="{ codorg: 'portdispatcherid', descripcion: 'portdispatchername' }"
							:itemValue="['codorg', 'descripcion']"
							itemText="label"
							:key="keydispatcher"
						>
						</pui-select>
					</v-col>
				</v-row>
			</pui-field-set>
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mportfacilities-form',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'mportfacilities',
			parentModelName: 'mports',
			modeldetail: true,
			parentModelPk: null,
			tabmodel: 'mportfacilities',
			showParentModelHeader: false,
			consigneeListBerths: [],
			consigneeListDis: [],
			consigneeKey: 0,
			selectedPortFacilities: [],
			array: [],
			keydispatcher: 0,
			orgs: null
		};
	},

	computed: {
		itemsToSelectPort() {
			return [{ countrycode: this.model.countrycode, portcode: this.model.portcode }];
		},
		itemsToSelectPortdispatcher() {
			return [{ codorg: this.model.portdispatcherid, descripcion: this.model.portdispatchername }];
		}
	},
	methods: {
		afterGetData() {
			this.orgs = this.model.org;
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);

			if (parentModel) {
				this.model.portcode = parentModel.pk.portcode;
				this.model.countrycode = parentModel.pk.countrycode;

				this.parentModelPk = parentModel.pk;
				this.showParentModelHeader = true;
				this.getConsignee();
			}
			if (this.$route.params.parentpk) {
				this.model.imofalformid = JSON.parse(atob(this.$route.params.pkgeneral)).portcode;
			}
		},
		getConsignee() {
			if (this.model.countrycode || this.model.portcode) {
				const urlEquipmentServices = '/gestaut/getORGConsigneeBerth';
				const params = {
					countrycode: this.model.countrycode,
					portcode: this.model.portcode
				};
				this.$puiRequests.getRequest(urlEquipmentServices, params, (response) => {
					this.setConsigneeBerth(response.data);
				});
				const urlEquipmentServices2 = '/gestaut/getORGConsigneeDispat';
				const params2 = {
					countrycode: this.model.countrycode,
					portcode: this.model.portcode
				};
				this.$puiRequests.getRequest(urlEquipmentServices2, params2, (response) => {
					this.setConsigneeDispat(response.data);
				});
			}
		},
		setConsigneeBerth(responseConsignee = []) {
			this.consigneeListBerths = [];
			this.consigneeListBerths = responseConsignee.map((c) => ({
				codorg: c.codorg,
				descripcion: c.descripcion,
				label: c.descripcion
			}));

			this.consigneeKey++;

			this.model.org = this.orgs;
		},
		setConsigneeDispat(responseConsignee = []) {
			this.consigneeListDis = [];
			this.consigneeListDis = responseConsignee.map((c) => ({
				codorg: c.codorg,
				descripcion: c.descripcion,
				label: c.descripcion
			}));
			this.keydispatcher++;
			this.keydispatcher++;
			this.model.org = this.orgs;
		}
	}
};
</script>
